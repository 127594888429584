import React from "react";
import Tab from "react-bootstrap/Tab";
import Fade from "react-reveal/Fade";
import Tabs from "react-bootstrap/Tabs";
import Carousel from "react-bootstrap/Carousel";
import { FaCheckCircle } from "react-icons/fa";
import "./style.scss";
import AMGCover from "../../images/amg_cover.jpg";
import oakforest_1 from "../../images/past-project/oakforest-1.jpeg";
import oakforest_2 from "../../images/past-project/oakforest-2.jpeg";
import oakforest_3 from "../../images/past-project/oakforest-3.jpeg";
import oakforest_4 from "../../images/past-project/oakforest-4.jpeg";
import oakforest_5 from "../../images/past-project/oakforest-5.jpeg";

import Forest_Pines_1 from "../../images/past-project/Forest-Pines1.jpeg";
import Forest_Pines_2 from "../../images/past-project/Forest-Pines2.jpeg";
import Forest_Pines_3 from "../../images/past-project/Forest-Pines3.jpeg";
import Forest_Pines_4 from "../../images/past-project/Forest-Pines4.jpeg";
import Forest_Pines_5 from "../../images/past-project/Forest-Pines5.jpeg";

import Parkway_Falls_1 from "../../images/past-project/Parkway-Falls1.jpeg";
import Parkway_Falls_2 from "../../images/past-project/Parkway-Falls2.jpeg";
import Parkway_Falls_3 from "../../images/past-project/Parkway-Falls3.jpeg";
import Parkway_Falls_4 from "../../images/past-project/Parkway-Falls4.jpeg";
import Parkway_Falls_5 from "../../images/past-project/Parkway-Falls5.jpeg";

import west_oaks_1 from "../../images/past-project/west-oaks-1.jpeg";
import west_oaks_2 from "../../images/past-project/west-oaks-2.jpeg";
import west_oaks_3 from "../../images/past-project/west-oaks-3.jpeg";
import west_oaks_4 from "../../images/past-project/west-oaks-4.jpeg";
import west_oaks_5 from "../../images/past-project/west-oaks-5.jpeg";

import bay_vista_1 from "../../images/past-project/Bay-Vista-1.jpeg";
import bay_vista_2 from "../../images/past-project/Bay-Vista-2.jpeg";
import bay_vista_3 from "../../images/past-project/Bay-Vista-3.jpeg";
import bay_vista_4 from "../../images/past-project/Bay-Vista-4.jpeg";
import bay_vista_5 from "../../images/past-project/Bay-Vista-5.jpeg";

const People = ({ leaderRef, groupRef, trackRef }) => {
  return (
    <>
      {/* <div className="sp_sec mb-4">
      
        <div className="cover_image">
          <img src={AMGCover} alt="AMG" />
        </div>
       
      </div> */}
      {/* <div className="sp_sec mb-4" ref={leaderRef}>
       
        <h4>
          <span className="fw-bolder text-app-primary"> The Afton Co </span>{" "}
          <span className="fw-normal text-app-primary">
            {" "}
            (The Development Group)
          </span>
        </h4>
     
        <hr />
        <p className="mb-0 text-justify">
          The Developer, Afton Co, is a seasoned real estate development firm
          consisting of a strong suite of development professionals with
          extensive experience in all facets of multifamily development. The
          Afton Co team members have developed over 10,000 units across multiple
          States, over the last 45 years with a combined multifamily development
          experience. Afton Co is a vertically integrated development company
          with its primary focus on the development of multifamily properties in
          primary and secondary markets throughout the State of Texas. Afton's
          development process includes all phases such as site selection, site
          planning, market needs analysis, pre-design, architectural,
          construction, stabilization, and final exit. Afton's current active
          multifamily pipeline includes development of over ~1,200 units at
          various stages of development, with an estimated 120 units just
          completed and received certificate of occupancy (CO) in May 2023; and
          ~1,200 units planned for construction start in 2024 and 2025 with
          deliveries through 2026 and 2027.
          <br />
          <br />
          With years of multifamily experience, the development team has
          fostered some exceptional relationships with various players such as
          Architects, Engineers, General Contractors, Property Management
          Companies, Lending Institutions, Institutional Buyers, Land Brokers,
          Limited Partners, General Partners, and Joint Venture Partners to
          excel in projects we develop. With Afton Group Corporate offices in
          Houston, TX, our multifamily development team is well positioned
          geographically to handle its projects across the State of Texas with
          ease and efficiency.
        </p>
        <br />
        <br />
        
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Kenneth-Steidley-_whitebg_es0gsc.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kenneth Steidley <span>President - Afton Co</span>
                </h3>
                <p className="text-justify">
                  Ken Steidley serves as Consultant of Development and Land
                  Acquisition at AMG. With over 30 years of commercial real
                  estate, Mr. Steidley has sold over 30,000 apartment units
                  valued at over $1.5 billion. Recognized as a leading expert in
                  the commercial real estate field, Mr. Steidley has represented
                  some of the largest multifamily real estate companies in the
                  country, and has been responsible for several multifamily key
                  transactions, including the sale, for one client of more than
                  4,000 apartment units. Mr. Steidley is an active part of the
                  commercial real estate community, being involved in both the
                  Houston Apartment Association and the TX Apartment
                  Association. Community involvement includes membership in the
                  Sugarcreek Country Club and St. Lawrence Catholic Church.
                  Since October 2020, Ken has successfully identified,
                  negotiated, contracted, and bought over 10 sites for AMG for a
                  total of over 150-acres in multiple high performing TX cities.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Larry_Waters_whitebg_y56t2r.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Larry Waters{" "}
                  <span>Consultant - Multifamily Construction</span>
                </h3>
                <p className="text-justify">
                  Larry has over 50 years of multifamily construction experience
                  with extensive working knowledge of various facets of
                  multifamily development process from site selection to site
                  planning, entitlements, zoning, utilities, pre-construction,
                  contractor selection, construction, leasing, stabilization,
                  and asset exits - the full lifecycle of multifamily investing.
                  Larry has been directly involved in the construction of over
                  100 apartment projects and 40,000 units across 18 different
                  States. Larry has developed apartments in multiple States: TX,
                  Ohio, Indiana, North and South Carolina, Virginia, Kansas,
                  Oklahoma, Maryland, Delaware, Utah, New Jersey, Pennsylvania,
                  Colorado, Georgia, and Florida. He has built and delivered
                  over $6 billion worth of multifamily projects over 50 years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="sp_sec mb-4" ref={groupRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary">
            {" "}
            Alamo Multifamily Group
          </span>{" "}
          <span className="fw-normal text-app-primary"> (The Developer)</span>
        </h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <p className="mb-0 text-justify">
          The Developer, Alamo Multifamily Group (AMG), is a seasoned real
          estate development firm consisting of a strong suite of development
          professionals with extensive experience in all facets of multifamily
          development. Team members have developed over 10,000 units across
          multiple States, over the last 45 years with a combined multifamily
          development experience. AMG is a vertically integrated development
          company with its primary focus on development of Class-A,
          garden-style, 4/5-Story wrap, and midrise multifamily properties in
          primary and secondary markets throughout the State of TX. AMG's
          development process includes all phases such as site selection, site
          planning, market needs analysis, pre-design, architectural,
          construction, stabilization, and final exit.
          <br />
          <br />
          AMG's current active multifamily pipeline includes development of over
          ~1,200 units at various stages of development, with an estimated 120
          units that received certificate of occupancy in May 2024. The
          remaining ~1,080 units that are shovel ready are planned for
          construction start in 2024 and 2025 with deliveries through 2026 and
          2027.
          <br />
          <br />
          With years of multifamily experience, the development team has
          fostered some exceptional relationships with various players such as
          Architects, Engineers, General Contractors, Property Management
          Companies, Lending Institutions, Institutional Buyers, Land Brokers,
          Limited Partners, General Partners, and Joint Venture Partners to
          excel in projects we develop.
        </p>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <div className="cover_image">
          <img src={AMGCover} alt="AMG" />
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={leaderRef}>
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Logan-Anjaneyulu_nh8nou.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Logan Anjaneyulu{" "}
                  <span>President - Alamo Multifamily Group</span>
                </h3>
                <p className="text-justify">
                  Logan Anjaneyulu aquired Southwestern Apartment Developers
                  (SWAD) in October 2020 and formed Alamo Multifamily Group
                  shortly thereafter. AMG is built upon SWAD's 55-year
                  multifamily development legacy founded by Mr. Fred Morgan in
                  1965 in Corpus Christi, Texas. AMG is a multifamily
                  development company, and its team members have combined, over
                  years of multifamily development experience. Logan Anjaneyulu
                  has over 20 years of real estate investing experience and his
                  expertise include syndication, development, and management of
                  various real estate projects. Logan has over 20 years of
                  corporate, construction, energy, commercial real estate
                  experience, with many successful exits. At AMG, Logan is
                  responsible for strategy, developement screening, and
                  execution of development projects. Logan holds a Civil
                  Engineering undergraduate degree from College of Engineering
                  Guindy at Anna University, Chennai, India; a Master of Science
                  (M.S) in Construction Management from Michigan State
                  University, East Lansing, Michigan; and an MBA from Kellogg
                  School of Management at Northwestern University, Evanston,
                  Illinois.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Screenshot%202024-08-04%20at%206.17.59%E2%80%AFAM.png?updatedAt=1722732551442"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Robert Young{" "}
                  <span>Strategic Real Estate Advisor - SRA Alamo Group </span>
                </h3>
                <p className="text-justify">
                  Robert founded and manages SRA Strategic Realty Advisors
                  operations and strategic directives. He guides SRA and it's
                  clients in the acquisition, disposition, leasing, development,
                  management, and financial analysis of real estate assets.
                  Prior to founding SRA, Robert was the Vice President of
                  Commercial Leasing at the Oldham Goodwin Group, LLC where he
                  led their Retail Division for Seven (7) Years. Robert received
                  his BBA in Business Management from Texas A&M University in
                  College Station. He is actively involved in the community
                  coaching basketball and volleyball for his daughters, serving
                  on several boards, and volunteering with Habitat for Humanity,
                  Project Unity, United Way, and the MDA Telethon. Robert
                  attends Grace Creekside Bible Church with his wife Hayley,
                  daughters Taylor, Sammie, and Laynie. Robert is a resident of
                  College Station and is a fully licensed Real Estate Broker
                  across the State of Texas. Robert also owns and manages
                  multiple retail and commercial assets and will serve as a
                  strategic partner to manage and advise on Alamo Group assets
                  and real estate properties.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Kenneth-Steidley-_whitebg_es0gsc.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kenneth Steidley{" "}
                  <span>Consultant - Development & Land Acquisition</span>
                </h3>
                <p className="text-justify">
                  Ken Steidley serves as Consultant of Development and Land
                  Acquisition at AMG. With over 30 years of commercial real
                  estate, Mr. Steidley has sold over 30,000 apartment units
                  valued at over $1.5 billion. Recognized as a leading expert in
                  the commercial real estate field, Mr. Steidley has represented
                  some of the largest multifamily real estate companies in the
                  country, and has been responsible for several multifamily key
                  transactions, including the sale, for one client of more than
                  4,000 apartment units. Mr. Steidley is an active part of the
                  commercial real estate community, being involved in both the
                  Houston Apartment Association and the TX Apartment
                  Association. Community involvement includes membership in the
                  Sugarcreek Country Club and St. Lawrence Catholic Church.
                  Since October 2020, Ken has successfully identified,
                  negotiated, contracted, and bought over 10 sites for AMG for a
                  total of over 150-acres in multiple high performing TX cities.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Larry_Waters_whitebg_y56t2r.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Larry Waters{" "}
                  <span>Consultant - Multifamily Construction</span>
                </h3>
                <p className="text-justify">
                  Larry has over 50 years of multifamily construction experience
                  with extensive working knowledge of various facets of
                  multifamily development process from site selection to site
                  planning, entitlements, zoning, utilities, pre-construction,
                  contractor selection, construction, leasing, stabilization,
                  and asset exits - the full lifecycle of multifamily investing.
                  Larry has been directly involved in the construction of over
                  100 apartment projects and 40,000 units across 18 different
                  States. Larry has developed apartments in multiple States: TX,
                  Ohio, Indiana, North and South Carolina, Virginia, Kansas,
                  Oklahoma, Maryland, Delaware, Utah, New Jersey, Pennsylvania,
                  Colorado, Georgia, and Florida. He has built and delivered
                  over $6 billion worth of multifamily projects over 50 years.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Van_Williamson-removebg-preview.png?updatedAt=1722790037156"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Van Williamson <span>VP and General Counsel</span>
                </h3>
                <p className="text-justify">
                  Van Williamson joined Alamo Equity as its Vice President and
                  General Counsel of Alamo Group in March of 2024. Van has been
                  practicing law in the state of TX for 21 years with experience
                  in corporate transactions, real estate, labor law,
                  construction law, and litigation. Prior to joining Alamo
                  Equity, Van served as General Counsel for a large utility
                  company in Texas. Van earned a Juris Doctorate from South
                  Texas College of Law, J.D. 2003. Van is responsible for
                  overseeing all legal matters at the Alamo Group including real
                  estate acquisitions, dispositions, compliance, and general
                  counsel. In addition, Van is responsible for supervising
                  outside counsel in Alamo Group's various transactions and
                  other legal matters.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={groupRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary">
            {" "}
            Radiant Capital Partners, LLC
          </span>{" "}
          <span className="fw-normal text-app-primary">
            {" "}
            (The GP Sponsor Group)
          </span>
        </h4>
        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <p className="mb-0 text-justify compact">
          Headquartered in Dallas, Texas, the Radiant Capital Partners Group
          consists of the Sponsor, and the General Partner. The Radiant Capital
          Partners group is co-founded by a consortium of experienced
          entrepreneurs and investors that include Mr. Logan Anjaneyulu (San
          Antonio, TX), Mr. Nick Punyamurthy (Richardson, TX), Mr. Anbu
          Nachimuthu (Frisco, TX) and Mr. Chris Minor (Bakersfield, CA). Mr.
          Nick is a successful Dallas based entrepreneur and business owner with
          over thirty years of investment and operational experience. He is the
          Founder, President and CEO of CompNova Solutions, an integrated
          technology solutions company headquartered in Richardson, Texas. Mr.
          Anbu is a successful Dallas based entrepreneur and Founder, Chairman
          and CEO of Carrus Health, LLC a high-end hospital system in Sherman,
          Texas. Mr. Logan is a successful San Antonio based entrepreneur and
          founder chairman and CEO of Alamo Group Companies, an integrated
          investment and real estate development company headquartered in Texas.
          Mr. Chris is a successful Southern California based retired
          entrepreneur who sold his electrical and instrumentation business (EIU
          of California, Inc) and has been an active real estate investor in
          California, Oregon, Idaho, Louisiana, and Texas.
        </p>
        <br />
        <br />
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Logan-Anjaneyulu_nh8nou.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Logan Anjaneyulu{" "}
                  <span>Managing Partner - Radiant Capital</span>
                  <span>
                    Founder President and CEO -{" "}
                    <a href="https://www.alamoequity.com/" target="_blank">
                      Alamo Group Companies
                    </a>
                  </span>
                </h3>
                <p className="text-justify">
                  Logan Anjaneyulu founded Alamo Group Companies (AGC) in 2017.
                  Alamo Group is an integrated investment and real estate
                  development company headquartered in Texas. Logan Anjaneyulu
                  has over 20 years of real estate investing experience and his
                  expertise include syndication, development, and management of
                  various real estate projects. Logan has over 20 years of
                  corporate, construction, energy, commercial real estate
                  experience, with many successful exits. At the Alamo Group,
                  Logan is responsible for strategy, capital raising, investment
                  screening, and execution. Logan holds a Civil Engineering
                  undergraduate degree from College of Engineering Guindy at
                  Anna University, Chennai, India; a Master of Science (M.S) in
                  Construction Management from Michigan State University, East
                  Lansing, Michigan; and an MBA from Kellogg School of
                  Management at Northwestern University, Evanston, Illinois. As
                  Founder and President, Logan provides mentorship and oversight
                  to the Executive Leadership team at Alamo Group Companies
                  (AGC) globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Nick-Punyamurthy-1-removebg-preview.png?updatedAt=1724097652680"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Nick Punyamurthy
                  <span>Partner - Radiant Capital</span>
                  <span>
                    Founder, President and CEO -{" "}
                    <a
                      href="http://www.compnova.com/clients.html"
                      target="_blank"
                    >
                      CompNova
                    </a>
                  </span>
                </h3>
                <p className="text-justify">
                  Founded CompNova with the idea of providing the best
                  consulting services at an economical price. Areas of focus
                  include management consulting, business process outsourcing
                  (BPO), Robotic Process Automation (RPA), Data Analytics,
                  information strategy planning, and project management.
                  Successful in producing profits every year since inception of
                  1996 even in the worst slumps. Grew the company at a better
                  than industry averages in terms net revenues, profits and
                  productivity per employee. InfoExperts was named “Top 500”
                  business in US by Diversity Business and as a “Top 30
                  Consulting Companies in Texas” by Texas Software News.
                  Maintained net earnings above industry averages. CompNova was
                  recognized by US Pan Asian Chamber of Commerce as "Fast 100"
                  companies. Expanded the company operations to multiple states.
                  Set up offshore partners for software development. Weathered
                  the downturn in the economy and consistently produced profits.
                  Set the course for rapid growth.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Anbu_Picture-removebg-preview.png?updatedAt=1724094926531"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Anbu Nachimuthu
                  <span>Partner - Radiant Capital</span>
                  <span>
                    Founder, Chairman, and CEO -{" "}
                    <a href="https://www.carrushealth.com/" target="_blank">
                      Carrus Health
                    </a>
                  </span>
                </h3>
                <p className="text-justify">
                  Anbu has over 35 years of entrepreneurial, corporate, finance,
                  and healthcare industry experience. He founded Carrus Health
                  in 2008, to provide the best healthcare services to patients
                  and corporations. Carrus Health teams are led by several of
                  the most experienced and qualified individuals and medical
                  experts from all parts of the world. These compassionate
                  professionals embody the mission, vision, and values of Carrus
                  Health to ensure that their teams work at their finest. Anbu
                  began Carrus Health as a two-unit hospital facility in
                  Sherman, Texas, in 2008, and has grown across Texas and
                  Oklahoma since then. Anbu and his team have followed their
                  vision of providing the finest healthcare facilities to
                  patients across the United States. In addition to founding,
                  owning, and running a top-class hospital system, Anbu is also
                  a passionate real estate investor and has a diversified real
                  estate investment portfolio including medical office
                  buildings, land, single family home and condo developments
                  amongst many others. Anbu has a bachelor’s degree in
                  Aeronautical Engineering from Madras In statute of Technology,
                  Chennai, India; a master’s degree in Aerospace Engineering
                  from Wichita State University, Kansas, and an MBA from The
                  University of Chicago.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Caldwell_Velnambi-removebg-preview-photoaidcom-lighten.png?updatedAt=1724098121235"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Caldwell Velnambi
                  <span>Partner - Radiant Capital</span>
                  <span>
                    Founder & Chairman -{" "}
                    <a href="https://noblq.com/" target="_blank">
                      Nobl Q
                    </a>
                  </span>
                </h3>
                <p className="text-justify">
                  Caldwell Velnambi, the founder and chairman of Nobl Q, has had
                  a remarkable 30-year career marked by groundbreaking
                  contributions, particularly in enterprise resource planning.
                  Under his guidance, Nobl Q has witnessed remarkable growth in
                  the ERP sector, with consulting and implementation being the
                  main focus, and has achieved significant milestones to
                  becoming Gold partners with SAP and Acumatica. With a career
                  defined by foresight, customer experience and resilience, he
                  has been at the forefront of the company's evolution.
                  Caldwell's track record is a testament to his ability to
                  transform challenges into opportunities, a quality that
                  continues to drive Nobl Q's success story. Caldwell remains
                  committed to Nobl Q's continued growth by driving inorganic
                  initiatives, ventures, and collaborations. Besides being an
                  entrepreneurial leader, Caldwell contributes extensively to
                  his cultural roots and is deeply involved in the Federation of
                  Tamil Sangams of North America (FeTNA). His commitment to
                  giving back to the local community aligns with our broader
                  mission of prioritizing people, celebrating diversity, and
                  making a meaningful impact. He resides in Coppell, Texas, with
                  his family.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Chris_Minor-removebg-preview.png?updatedAt=1724090582355"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Chris Minor
                  <span>Partner - Radiant Capital</span>
                  <span>
                    Real Estate Investor, and Retired Owner and President -{" "}
                    <a
                      href="https://www.linkedin.com/in/chris-minor-a051b730/"
                      target="_blank"
                    >
                      EIU of California, Inc
                    </a>
                  </span>
                </h3>
                <p className="text-justify">
                  Chris has over 40 years of entrepreneurial, corporate,
                  construction, and energy industry experience. He is a Savvy
                  real estate investor with diversified investments. He has
                  owned, operated, and sold a conglomerate electrical and
                  instrumentation energy services business that provided
                  construction, maintenance and turnaround services to the oil
                  and gas, and construction industry. Chris grew the business
                  from a $4 million revenue regional company to over a $100
                  million in annual P&L global company for EIU, Inc. Chris is
                  also a savvy real estate investor and has investments in a
                  diversified asset portfolio including land, office buildings,
                  senior living and assisted living facilities, multifamily
                  housing, and other industrial office buildings. He has active
                  investments in the State of California, Idaho, Oregon, Texas,
                  and Louisiana. Chris has a bachelor’s degree in business
                  administration from Idaho State University. He resides in
                  Bakersfield, California, with his family.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Logan-Anjaneyulu_nh8nou.jpg"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Logan Anjaneyulu <span>Founder, President, & CEO</span>
                </h3>
                <p className="text-justify">
                  Logan Anjaneyulu founded Alamo Equity, an Alamo Group Company
                  in 2017, and AMG shortly thereafter. AMG is a multifamily
                  development company, and its team members have combined, over
                  years of multifamily development experience. Logan Anjaneyulu
                  has over 20 years of real estate investing experience and his
                  expertise include syndication, development, and management of
                  various real estate projects. Logan has over 20 years of
                  corporate, construction, energy, commercial real estate
                  experience, with many successful exits. At the Alamo Group,
                  Logan is responsible for strategy, capital raising, investment
                  screening, and execution. Logan holds a Civil Engineering
                  undergraduate degree from College of Engineering Guindy at
                  Anna University, Chennai, India; a Master of Science (M.S) in
                  Construction Management from Michigan State University, East
                  Lansing, Michigan; and an MBA from Kellogg School of
                  Management at Northwestern University, Evanston, Illinois. As
                  Founder and President, Logan provides mentorship and oversight
                  to the Executive Leadership team at Alamo Group Companies
                  (AGC) globally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br /> */}
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={leaderRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary"> The Sirer Group</span>{" "}
          <span className="fw-normal text-app-primary">
            {" "}
            (SPV Global Capital Partner)
          </span>
        </h4>

        {/* </Fade> */}
        <hr />
        {/* <Fade bottom> */}
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/0H1A9850-scaled-1-removebg-preview%20(1)%20(1).png?updatedAt=1722731709801"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Ersin Sirer <span>Founder & Chairperson - Sirer Global</span>
                </h3>
                <p className="text-justify">
                  Ersin Sirer, the founder of Sirer Global Investments and Sirer
                  HMO Residences, has a wealth of experience & expertise in
                  developing and investing in the UK property market. His broad
                  experience ranges from 30 years of long-term property rental
                  investments, Prime London developments, New Build
                  developments, Commercial to Residential developments and
                  facilitator of Mezzanine debt for developers. He has also
                  headed property investment syndicates investing in UK and
                  international real estate. Ersin's firm belief is that
                  opportunity comes from a good network of well-connected
                  people, delivering results based on firm current market
                  conditions and future analyses of where the market is heading.
                  Sirer Property Investments is a trading name that encompasses
                  the Sirer Property Group, headed by Ersin Sirer, a
                  London-based businessman with 40 years of property and
                  business experience. The Sirer Property Group covers all
                  aspects of property-based investments, from high-end bespoke
                  residential developments, new builds, and office/warehouse to
                  residential conversions, to long-term high-yielding rental
                  investments. Sirer Group also provides strategic business
                  development advice and facilitate companies to expand their
                  market share through a network of credible connections. Sirer
                  Global has over 25 years of active involvement in property
                  development covering all property market segments, from
                  high-end bespoke residential developments, new builds,
                  warehouse/office to residential multi-unit conversions, to
                  long-term rental investments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sp_sec mb-4" ref={leaderRef}>
        {/* <Fade bottom> */}
        <h4>
          <span className="fw-bolder text-app-primary">
            {" "}
            SRA Strategic Realty Advisors{" "}
          </span>{" "}
          <span className="fw-normal text-app-primary">
            {" "}
            (The Real Estate Advisory Group)
          </span>
        </h4>
        {/* </Fade> */}
        <hr />
        <p className="mb-0 text-justify compact">
          The Property Advisor, SRA Strategic Realty Advisors, LLC is an
          integrated, comprehensive real estate brokerage firm experienced in
          retail, office, industrial, multifamily, and development sectors of
          commercial real estate committed to aligning landlords, tenants, and
          investors with their strategic objectives. SRA’s mission is to assist
          clients, investors, and team members in reaching financial
          independence. Headquartered in college Station, Texas, SRA operates
          across the State of Texas and beyond.
        </p>
        <br />
        <br />
        <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Screenshot%202024-08-04%20at%206.17.59%E2%80%AFAM.png?updatedAt=1722732551442"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Robert Young{" "}
                  <span>Strategic Real Estate Advisor - SRA Alamo Group </span>
                </h3>
                <p className="text-justify">
                  Robert founded and manages SRA Strategic Realty Advisors
                  operations and strategic directives. He guides SRA and it's
                  clients in the acquisition, disposition, leasing, development,
                  management, and financial analysis of real estate assets.
                  Prior to founding SRA, Robert was the Vice President of
                  Commercial Leasing at the Oldham Goodwin Group, LLC where he
                  led their Retail Division for Seven (7) Years. Robert received
                  his BBA in Business Management from Texas A&M University in
                  College Station. He is actively involved in the community
                  coaching basketball and volleyball for his daughters, serving
                  on several boards, and volunteering with Habitat for Humanity,
                  Project Unity, United Way, and the MDA Telethon. Robert
                  attends Grace Creekside Bible Church with his wife Hayley,
                  daughters Taylor, Sammie, and Laynie. Robert is a resident of
                  College Station and is a fully licensed Real Estate Broker
                  across the State of Texas. Robert also owns and manages
                  multiple retail and commercial assets and will serve as a
                  strategic partner to manage and advise on Alamo Group assets
                  and real estate properties.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://ik.imagekit.io/alamogroup/dev/leaders/Van_Williamson-removebg-preview.png?updatedAt=1722790037156"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Van Williamson <span>VP and General Counsel</span>
                </h3>
                <p className="text-justify">
                  Van Williamson joined Alamo Equity as its Vice President and
                  General Counsel of Alamo Group in March of 2024. Van has been
                  practicing law in the state of TX for 21 years with experience
                  in corporate transactions, real estate, labor law,
                  construction law, and litigation. Prior to joining Alamo
                  Equity, Van served as General Counsel for a large utility
                  company in Texas. Van earned a Juris Doctorate from South
                  Texas College of Law, J.D. 2003. Van is responsible for
                  overseeing all legal matters at the Alamo Group including real
                  estate acquisitions, dispositions, compliance, and general
                  counsel. In addition, Van is responsible for supervising
                  outside counsel in Alamo Group's various transactions and
                  other legal matters.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="single_sp">
          <div className="row flex-md-row-reverse align-items-center">
            <div className="col-md-3">
              <div className="sp_img">
                <img
                  src={
                    "https://res.cloudinary.com/cloudpencils-media/image/upload/v1681317745/image001_002_qmyr88.png"
                  }
                  alt="Sponsor"
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="sp_cnt">
                <h3>
                  Kyle Lavergne
                  <span>Associate, Acquisitions - Investor Relations</span>
                </h3>
                <p className="text-justify">
                  Kyle specializes in acquisitions and underwriting for Alamo.
                  Prior to joining Alamo Kyle specialized in multi-family and
                  student housing acquisitions for high-net-worth individuals
                  and companies. He also privately consulted in underwriting for
                  groups out of New York, Arizona, Florida, TX, and Michigan.
                  Kyle has been tasked with underwriting individual
                  institutional deals valued at $100+ Million. He has also
                  garnered extensive knowledge in the restructuring of
                  struggling portfolios.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* </Fade> */}
      </div>
      <div className="sp_sec mb-4" ref={trackRef}>
        {/* <Fade bottom> */}
        <h4 className="fw-bolder  text-app-primary">Track Record</h4>
        {/* </Fade> */}
        <hr />
        <div className="row">
          <div className="col-md-12 text-center">
            {/* <Fade bottom> */}
            <div className="text-justify mb-4 help-text-track">
              Alamo Multifamily Group (AMG) was formed by acquisition of
              Southwestern Apartment Developers (SWAD) in October 2020. AMG is
              built upon SWAD's 55-year multifamily development legacy founded
              by Mr. Fred Morgan in 1965 in Corpus Christi, Texas. AMG is a
              multifamily development company, and its team members have
              combined, over years of multifamily development experience. AMG
              onboarded a strong suite of multifamily talent with extensive
              experience of multifamily land acquisition, zoning, vertical
              development, asset management, and asset sale experience. The
              project experience and track record shown in the table below with
              excess of 10,000 multifamily units represents the overall
              development and construction experience of the combined SWAD-AMG
              team.
            </div>
            {/* </Fade> */}

            {/* <Fade bottom> */}
            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      Multifamily Project Name
                    </th>
                    <th>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "8.2rem" }}>
                      City, State
                    </th>
                    <th>Units Built</th>

                    <th style={{ width: "5.2rem" }}>Cost Basis</th>
                    <th style={{ width: "5.2rem" }}>Equity Invested</th>
                    <th style={{ width: "5.2rem" }}>Disposition Price</th>
                    <th style={{ width: "5.2rem" }}>Realized IIR</th>
                    <th style={{ width: "5.2rem" }}>Equity Multiple</th>
                    <th style={{ width: "5.2rem" }}>Hold Period (in years)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Parc at 980 *</td>
                    <td>1996</td>
                    <td>Garden Style</td>
                    <td className="text-start">Lawrenceville, GA</td>
                    <td>360</td>
                    <td>$50.7 M</td>
                    <td>$13.2 M</td>
                    <td>$64.6 M</td>
                    <td>32.8%</td>
                    <td>2.5x</td>
                    <td>6.5</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Korman - Avenue at Malvern *</td>
                    <td>1997</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Malvern, PA</td>
                    <td>153</td>
                    <td>$21.7 M</td>
                    <td>$5.9 M</td>
                    <td>$30.4 M</td>
                    <td>33.5%</td>
                    <td>2.3x</td>
                    <td>4.6</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">
                      Mallard Crossing Apartments *
                    </td>
                    <td>1997</td>
                    <td>Garden Style</td>
                    <td className="text-start">Loveland, OH</td>
                    <td>350</td>
                    <td>$50.4 M</td>
                    <td>$15.1 M</td>
                    <td>$74.9 M</td>
                    <td>38.2%</td>
                    <td>2.4x</td>
                    <td>6.8</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">Brandywine Woods *</td>
                    <td>1998</td>
                    <td>Garden Style</td>
                    <td className="text-start">Bear, DE</td>
                    <td>265</td>
                    <td>$38.4 M</td>
                    <td>$10.8 M</td>
                    <td>$48.5 M</td>
                    <td>30.4%</td>
                    <td>2.2x</td>
                    <td>6.3</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td className="text-start">River Crossing *</td>
                    <td>1999</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Indianapolis, IN</td>
                    <td>314</td>
                    <td>$45.7 M</td>
                    <td>$13.3 M</td>
                    <td>$56.6 M</td>
                    <td>36.9%</td>
                    <td>2.3x</td>
                    <td>6.2</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">Tradition at Mallard Creek *</td>
                    <td>1999</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charlotte, NC</td>
                    <td>360</td>
                    <td>$53.3 M</td>
                    <td>$13.9 M</td>
                    <td>$65.8 M</td>
                    <td>24.9%</td>
                    <td>2.4x</td>
                    <td>7.2</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="text-start">
                      Korman - Avenue at Somerset *
                    </td>
                    <td>2001</td>
                    <td>Garden Style</td>
                    <td className="text-start">Somerset, NJ</td>
                    <td>301</td>
                    <td>$45.2 M</td>
                    <td>$13.1 M</td>
                    <td>$66.2 M</td>
                    <td>27.8%</td>
                    <td>2.6x</td>
                    <td>6.0</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td className="text-start">
                      Vineyards of Colorado Springs *
                    </td>
                    <td>2002</td>
                    <td>Garden Style</td>
                    <td className="text-start">Colorado Springs, CO</td>
                    <td>304</td>
                    <td>$46.3 M</td>
                    <td>$12.0 M</td>
                    <td>$66.7 M</td>
                    <td>24.5%</td>
                    <td>2.4x</td>
                    <td>5.6</td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td className="text-start">Siena Apartments *</td>
                    <td>2003</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Houston, TX</td>
                    <td>224</td>
                    <td>$34.5 M</td>
                    <td>$10.4 M</td>
                    <td>$44.2 M</td>
                    <td>24.4%</td>
                    <td>2.3x</td>
                    <td>4.2</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td className="text-start">Stone Point Apartments *</td>
                    <td>2005</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Annapolis, MD</td>
                    <td>312</td>
                    <td>$48.6 M</td>
                    <td>$12.6 M</td>
                    <td>$59.1 M</td>
                    <td>27.6%</td>
                    <td>2.3x</td>
                    <td>6.7</td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td className="text-start">Cypress Legends *</td>
                    <td>2006</td>
                    <td>Garden Style</td>
                    <td className="text-start">Fort Myers, FL</td>
                    <td>332</td>
                    <td>$52.2 M</td>
                    <td>$13.1 M</td>
                    <td>$72.6 M</td>
                    <td>29.9%</td>
                    <td>2.3x</td>
                    <td>7.3</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td className="text-start">Ingleside Plantation *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charleston, SC</td>
                    <td>304</td>
                    <td>$48.4 M</td>
                    <td>$12.1 M</td>
                    <td>$72.1 M</td>
                    <td>38.1%</td>
                    <td>2.6x</td>
                    <td>6.3</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td className="text-start">Pinnacle Ridge Apartments *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">Dallas, TX</td>
                    <td>296</td>
                    <td>$47.6 M</td>
                    <td>$11.9 M</td>
                    <td>$68.4 M</td>
                    <td>28.4%</td>
                    <td>2.3x</td>
                    <td>6.1</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td className="text-start">Park Hudson Apartments *</td>
                    <td>2007</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>232</td>
                    <td>$37.7 M</td>
                    <td>$11.3 M</td>
                    <td>$52.8 M</td>
                    <td>39.9%</td>
                    <td>2.6x</td>
                    <td>5.5</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td className="text-start">Bay Vista Apartments *</td>
                    <td>2008</td>
                    <td>Wrap Style</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>169</td>
                    <td>$27.8 M</td>
                    <td>$8.3 M</td>
                    <td>$33.8 M</td>
                    <td>30.7%</td>
                    <td>2.3x</td>
                    <td>4.3</td>
                  </tr>
                  <tr>
                    <td>16</td>
                    <td className="text-start">Ingleside Plantation *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Charleston, SC</td>
                    <td>304</td>
                    <td>$50.7 M</td>
                    <td>$15.2 M</td>
                    <td>$72.6 M</td>
                    <td>24.0%</td>
                    <td>2.2x</td>
                    <td>7.3</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td className="text-start">Hunter's Cove *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Waxahacie, TX</td>
                    <td>192</td>
                    <td>$32.4 M</td>
                    <td>$9.1 M</td>
                    <td>$44.9 M</td>
                    <td>37.1%</td>
                    <td>2.2x</td>
                    <td>3.7</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td className="text-start">Villa Lago Apartments *</td>
                    <td>2008</td>
                    <td>Garden Style</td>
                    <td className="text-start">Ft. Worth, TX</td>
                    <td>204</td>
                    <td>$34.8 M</td>
                    <td>$9.4 M</td>
                    <td>$43.5 M</td>
                    <td>35.8%</td>
                    <td>2.6x</td>
                    <td>4.8</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td className="text-start">Vance Jackson Legends *</td>
                    <td>2009</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>400</td>
                    <td>$69.2 M</td>
                    <td>$18.7 M</td>
                    <td>$101.9 M</td>
                    <td>31.5%</td>
                    <td>2.6x</td>
                    <td>7.4</td>
                  </tr>
                  <tr>
                    <td>20</td>
                    <td className="text-start">Encore Crossing Apartments *</td>
                    <td>2009</td>
                    <td>Garden Style</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>234</td>
                    <td>$41.2 M</td>
                    <td>$12.4 M</td>
                    <td>$58.9 M</td>
                    <td>32.0%</td>
                    <td>2.3x</td>
                    <td>5.2</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td className="text-start">Boulder Creek *</td>
                    <td>2010</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>400</td>
                    <td>$70.8 M</td>
                    <td>$18.4 M</td>
                    <td>$91.9 M</td>
                    <td>32.3%</td>
                    <td>2.2x</td>
                    <td>7.1</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td className="text-start">Belle Forte Apartments *</td>
                    <td>2011</td>
                    <td>Garden Style</td>
                    <td className="text-start">Beaumont, TX</td>
                    <td>360</td>
                    <td>$64.7 M</td>
                    <td>$19.4 M</td>
                    <td>$84.5 M</td>
                    <td>28.0%</td>
                    <td>2.6x</td>
                    <td>6.9</td>
                  </tr>
                  <tr>
                    <td>23</td>
                    <td className="text-start">The Plantation I *</td>
                    <td>2011</td>
                    <td>Garden Style</td>
                    <td className="text-start">Mission, TX</td>
                    <td>150</td>
                    <td>$27.3 M</td>
                    <td>$7.1 M</td>
                    <td>$36.8 M</td>
                    <td>24.7%</td>
                    <td>2.2x</td>
                    <td>3.8</td>
                  </tr>
                  <tr>
                    <td>24</td>
                    <td className="text-start">
                      Bay Vista Pointe Apartments *
                    </td>
                    <td>2013</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">Corpus Christi, TX</td>
                    <td>169</td>
                    <td>$31.2 M</td>
                    <td>$9.0 M</td>
                    <td>$42.2 M</td>
                    <td>27.5%</td>
                    <td>2.5x</td>
                    <td>3.5</td>
                  </tr>
                  <tr>
                    <td>25</td>
                    <td className="text-start">West Oak Apartments *</td>
                    <td>2013</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>352</td>
                    <td>$65.6 M</td>
                    <td>$17.1 M</td>
                    <td>$90.9 M</td>
                    <td>37.4%</td>
                    <td>2.3x</td>
                    <td>7.5</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td className="text-start">The Plantation II *</td>
                    <td>2013</td>
                    <td>Garden Style</td>
                    <td className="text-start">Mission, TX</td>
                    <td>150</td>
                    <td>$28.3 M</td>
                    <td>$8.5 M</td>
                    <td>$34.1 M</td>
                    <td>38.1%</td>
                    <td>2.4x</td>
                    <td>4.5</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td className="text-start">Parkway Fall Apartments *</td>
                    <td>2014</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Marcos, TX</td>
                    <td>192</td>
                    <td>$36.7 M</td>
                    <td>$10.6 M</td>
                    <td>$48.6 M</td>
                    <td>28.4%</td>
                    <td>2.3x</td>
                    <td>5.3</td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td className="text-start">Oak Forest Apartments *</td>
                    <td>2015</td>
                    <td>Garden Style</td>
                    <td className="text-start">Victoria, TX</td>
                    <td>264</td>
                    <td>$51.1 M</td>
                    <td>$14.3 M</td>
                    <td>$70.6 M</td>
                    <td>28.1%</td>
                    <td>2.6x</td>
                    <td>7.5</td>
                  </tr>
                  <tr>
                    <td>29</td>
                    <td className="text-start">Oak Tree Apartments *</td>
                    <td>2015</td>
                    <td>Garden Style</td>
                    <td className="text-start">Kingsville, TX</td>
                    <td>192</td>
                    <td>$37.7 M</td>
                    <td>$10.6 M</td>
                    <td>$53.6 M</td>
                    <td>36.9%</td>
                    <td>2.6x</td>
                    <td>6.1</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td className="text-start">Forest Grove Apartments *</td>
                    <td>2019</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>84</td>
                    <td>$16.7 M</td>
                    <td>$5.0 M</td>
                    <td>$21.8 M</td>
                    <td>30.5%</td>
                    <td>2.2x</td>
                    <td>4.2</td>
                  </tr>
                  <tr>
                    <td>31</td>
                    <td className="text-start">Forest Pines Apartments *</td>
                    <td>2019</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>240</td>
                    <td>$48.3 M</td>
                    <td>$12.1 M</td>
                    <td>$68.3 M</td>
                    <td>33.7%</td>
                    <td>2.4x</td>
                    <td>5.3</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td className="text-start">Spurs Lane Apartments **</td>
                    <td>2024</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>120</td>
                    <td>$25.4 M </td>
                    <td>$25.0 M </td>

                    <td>$42.0 M **</td>
                    <td>32%</td>
                    <td>4.0x</td>
                    <td>4.2</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>
                      Projects Developed by The Sponsor and The Developer Teams
                      *
                    </th>

                    <th>8,283</th>
                    <th>$1,335.2 M</th>
                    <th>$373.9 M</th>
                    <th>$1,841.8 M</th>
                    <th>31%</th>
                    <th>2.4x</th>
                    <th>5.8</th>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* 
            <h5 className="text-app-primary text-start">
              Our Current Projects
            </h5>
            <div className="table table-responsive mb-5 m-auto">
              <table className="table table-bordered mb-0 table-sm table-track-record">
                <thead>
                  <tr className="theme-bg-table">
                    <th>S.No</th>
                    <th className="text-start" style={{ width: "11.8rem" }}>
                      Multifamily Project Name
                    </th>
                    <th>Year</th>
                    <th style={{ width: "6.2rem" }}>Type</th>
                    <th className="text-start" style={{ width: "8.2rem" }}>
                      City, State
                    </th>
                    <th>Target Unit Development</th>

                    <th style={{ width: "5.2rem" }}>Total Cost Basis</th>
                    <th style={{ width: "5.2rem" }}>Target Equity Raise</th>
                    <th style={{ width: "5.2rem" }}>Target Exit Price</th>
                    <th style={{ width: "5.2rem" }}>Target IIR</th>
                    <th style={{ width: "5.2rem" }}>Target Equity Multiple</th>
                    <th style={{ width: "5.2rem" }}>Target Hold Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td className="text-start">Spurs Lane Apartments **</td>
                    <td>2022</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>120</td>
                    <td>$25.6 M</td>
                    <td>$4.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td className="text-start">Afton Oak Apartments ***</td>
                    <td>2023</td>
                    <td>4-Story Wrap</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>478</td>
                    <td>$120.0 M</td>
                    <td>$35.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td className="text-start">Stone Oak Apartments ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">San Antonio, TX</td>
                    <td>264</td>
                    <td>$65.0 M</td>
                    <td>$20.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td className="text-start">Bryan/College Station ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">College Station, TX</td>
                    <td>336</td>
                    <td>$75.0 M</td>
                    <td>$25.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td className="text-start">Baytown Apartments ***</td>
                    <td>2024</td>
                    <td>Garden Style</td>
                    <td className="text-start">Baytown, TX</td>
                    <td>360</td>
                    <td>$80.0 M</td>
                    <td>$25.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td className="text-start">Taylor Creek Apartments ***</td>
                    <td>2024</td>
                    <td>BTR Product</td>
                    <td className="text-start">Taylor, TX</td>
                    <td>422</td>
                    <td>$105.0 M</td>
                    <td>$30.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td className="text-start">Richmond Apartments ***</td>
                    <td>2023</td>
                    <td>Garden Style</td>
                    <td className="text-start">Richmond, TX</td>
                    <td>360</td>
                    <td>$80.0 M</td>
                    <td>$55.0 M</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="theme-bg-table">
                    <th colSpan={5}>
                      Our Current Projects Under Development (2022 - 2024)
                    </th>

                    <th>2,340</th>
                    <th>$550.6 M</th>
                    <th>$194.0 M</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div> */}
            {/* </Fade> */}
            {/* <img
                src="https://res.cloudinary.com/cloudpencils-media/image/upload/v1672798616/funding/production/Track_Record_Image_tbketf.jpg"
                className="w-75  mb-4"
              /> */}
          </div>

          {/* <Fade bottom> */}
          <div className="col-md-12">
            <div className="row ">
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/First_Image_m8qzwq.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>Belle Forte Apartments - Beaumont, TX - 2011 *</span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/West_Oak_2_vlhscg.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>
                    West Oak Luxury Apartments - San Antonio, TX - 2013 *
                  </span>
                </div>
              </div>

              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/oak-forest-aerial-04_evyhnh.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>Oak Forest Apartments - Victoria, TX - 2015 *</span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/forest-pines-apartments-bryan-tx-primary-photo_oqo4s8.jpg"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>
                    Forest Pine Apartments - College Station, TX - 2019 *
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/New%20Braunfels%20Image.png?updatedAt=1724437213915"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>
                    New Braunfels Apartments - New Braunfels, TX - 2020 ***
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src="https://ik.imagekit.io/alamogroup/dev/Spurs%20Lane%20Image.jpg?updatedAt=1724437235321"
                  className="w-100 mb-1 custom-height-fund-img"
                />
                <div className="text-justify mb-5 mt-2 help-text-track text-center fw-bold">
                  <span>Spurs Lane Apartments - San Antonio, TX - 2024 **</span>
                </div>
              </div>
            </div>
          </div>
          {/* </Fade> */}
          {/* <Fade bottom> */}
          <div className="text-justify help-text-track">
            <ul>
              <li>
                Track Record <b className="app-color">Note 1* </b> Projects 1-31
                above are projects developed prior to 2020 and were developed by
                Afton Co team members as part of Southwestern Apartment
                Developers LLC.
              </li>
              <li>
                Track Record <b className="app-color">Note 2** </b> Project 32
                above is Spurs Lane Apartment Project that received full
                Certificate of Occupancy (CO) in May 2024. Target Exit Price of
                $42 million is a future forcast.
              </li>
            </ul>
          </div>
          {/* </Fade> */}
        </div>
      </div>
      <div className="sp_sec mb-4">
        {/* <Fade bottom> */}
        <h4 className="fw-bolder text-app-primary">Past Projects*</h4>
        {/* </Fade> */}
        <hr />
        <Tabs defaultActiveKey="pines" className="custom_tab3">
          <Tab eventKey="pines" title="Forest Pines">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={Forest_Pines_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Forest_Pines_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          852 N Harvey Mitchell, Bryan, TX 77807
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          328 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          280,440 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          18.50 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2020
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          REIT Owned
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          14 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          574
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="oak" title="Oak Forest">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  ``
                  <Carousel.Item>
                    <img src={oakforest_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={oakforest_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          5312 N. John Stockbauer, Victoria, TX 78249
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          264 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          219,516 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          28.40 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2015
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Completed
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Wood Style Floors
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          11 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          470
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>

          <Tab eventKey="parkway" title="Parkway Falls">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={Parkway_Falls_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={Parkway_Falls_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          1811 Medical Pkwy., San Marcos, TX 78666
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          192 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          159,936 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          10.14 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>8 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          357
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="west" title="West  Oak">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={west_oaks_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={west_oaks_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          14838 Vance Jackson Rd., San Antonio TX 78249
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          352 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          272,756 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          16.00 Acres
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2013
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          Garden Style
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Stone and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          15 3-Story Building + Clubhouse
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Parking:</b>
                          704
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="bay" title="Bay Vista">
            <div className="row">
              <div className="col-md-6">
                <Carousel>
                  <Carousel.Item>
                    <img src={bay_vista_1} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_2} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_3} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_4} alt="Alamo" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={bay_vista_5} alt="Alamo" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6">
                <div className="property_detail">
                  <ul>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Location:</b>
                          522 Hancock Avenue and 802 Carancahua, Corpus Christi,
                          TX 78404
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Units:</b>
                          169 + 164 = 333 Units
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Rentable Area:</b>
                          280,720 sq.ft.
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Land Size:</b>
                          2008 & 2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Completed:</b>
                          2008 & 2014
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Status:</b>
                          Sold
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Style:</b>
                          4-style “Wrap”
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Exterior:</b>
                          Brick and Hardi-Plank Siding
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Roof:</b>
                          Pitched Composition Shingles
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Flooring:</b>
                          Carpet, Laminated & Vinyl Tile
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div>
                          <FaCheckCircle />
                        </div>
                        <div>
                          <b>Buildings:</b>
                          350 + 290 Parking Spaces | 4-Story Parking Structure
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {/* <Tabs defaultActiveKey="leader" className="mb-3 custom-css-tabs" fill>
          <Tab eventKey="leader" title="Leadership Team"></Tab>
          <Tab eventKey="track" title="Track Record"></Tab>
          <Tab eventKey="oak" title="Past Projects"></Tab>
        </Tabs> */}
      </div>
    </>
  );
};

export default People;
